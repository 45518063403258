import React from 'react';

import imageDecorationOvalMain1 from '../../assets/images/vectors/decorations/oval-main-1.svg';
import imageDecorationOvalMain1Mobile from '../../assets/images/vectors/decorations/oval-main-1-mobile.svg';

import useResize from '../../utils/useResize';

const Decoration1 = () => {
  const { isMobile } = useResize();
  return (
    <div className="cfs__r__decoration cfs__r__decoration--1">
      <img
        src={!isMobile ? imageDecorationOvalMain1 : imageDecorationOvalMain1Mobile}
        alt="decoration"
      />
    </div>
  );
};

export default Decoration1;
