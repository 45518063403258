import React, { useState } from 'react';
import Modal from './Modal';

const StartJourneyButton = ({ text, styleName }) => {
  const [isOpenModal, setStateModal] = useState(false);
  const setModalIsOpen = (state) => {
    setStateModal(state);
  };
  return (
    <div>
      <div onClick={() => setModalIsOpen(true)} className={styleName || 'c-btn'}>
        {text}
      </div>
      {isOpenModal && (
        <Modal handleClose={() => setModalIsOpen(false)}>
          <div className="common-modal__content--pricing">
            <div className="c-h5--bold">Become a member</div>
            <p>
              If you want to become a member, please reach out to the person who gave you this link.
            </p>
            <button className="c-btn" onClick={() => setModalIsOpen(false)} type="button">
              Okay
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};
export default StartJourneyButton;
