import React from 'react';

import iconClose from '../assets/images/vectors/icons/close.svg';

function Modal({ handleClose, children }) {
  return (
    <div className="modal">
      <div className="common-modal">
        <button className="c-btn-reset common-modal__btn" onClick={handleClose} type="button">
          <img src={iconClose} alt="close" />
        </button>
        <div className="common-modal__content">{children}</div>
      </div>
    </div>
  );
}

export default Modal;
